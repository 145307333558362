import {Link} from "react-router-dom";


export const MainContactSection = () => {
    return (
        <section className="main-contact-section">
            <div className="layout1">
                <div className="main-contact-row">
                    <div className="col-contact col-title wow fadeInUp">Contact Info</div>
                    <div className="col-contact col-contact-item wow fadeInUp">
                        <dl>
                            <dt>전화번호</dt>
                            <dd><a href="tel:031-348-8157">031-348-8157</a></dd>
                        </dl>
                    </div>
                    <div className="col-contact col-contact-item wow fadeInUp">
                        <dl>
                            <dt>이메일</dt>
                            <dd><a href="mailto:help@erst.kr">help@erst.kr</a></dd>
                        </dl>
                    </div>
                    <div className="col-contact col-contact-item wow fadeInUp">
                        <dl>
                            <dt>고객센터</dt>
                            <dd>평일 09:00 - 18:00</dd>
                        </dl>
                    </div>
                    <div className="col-contact col-contact-item wow fadeInUp"><Link className="vbtn btn-line-white-white large btn-contact-form"  to="/customer/qna">
                                                                                  문의하기</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}