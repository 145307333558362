import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './link.css';
import {CookiesProvider} from "react-cookie";
import {createRoot} from "react-dom/client";

const container = document.getElementById('root');
const root = createRoot(container); // TypeScript 사용 시 createRoot(container!)로 적용
root.render(
    <CookiesProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
