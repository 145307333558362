
import FarmdaCardItem from "./SmallComponents/FarmdaCardItem";
import FarmdaCardItemData from "../Data/FarmdaCardItemData";
import {useState} from "react";
import {useEffect} from "react";
import $ from "jquery";
import {gsap} from "gsap";
import ArticleTitle from "./SmallComponents/Article/ArticleTitle";
import ArticleText from "./SmallComponents/Article/ArticleText";
import ArticleImage from "./SmallComponents/Article/ArticleImage";
import {useOutletContext} from "react-router-dom";
import SubVisualRow from "./SmallComponents/SubVisualRow";
import SubNavBusiness from "./SmallComponents/SubNavBusiness";



const AgriculturalProductsDistribution = ()=> {

    let [farmdaCardItemData] = useState(FarmdaCardItemData);
    const { data } = useOutletContext();
    if(data.subArticleData != null || data.subArticleData != undefined) {
        return (
            <>
                <SubVisualRow current={data.current}></SubVisualRow>
                <SubNavBusiness></SubNavBusiness>
                <article className="sub-article">
                    <div className="sub-article-inner">
                        <div className="sub-article-title type2" id="sub-article-title-farmda">
                            <ArticleTitle subArticleData={{data}}></ArticleTitle>
                            <ArticleText subArticleData={{data}}></ArticleText>
                        </div>
                        <ArticleImage subArticleData={{data}}></ArticleImage>
                    </div>
                </article>
                <FarmdaCardItem farmdaCardItemData={farmdaCardItemData}></FarmdaCardItem>
            </>
        )
    } else {
        return null;
    }
}






export default AgriculturalProductsDistribution;



